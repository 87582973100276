<template>
  <div class="container">
    <div style="background: #fff; padding: 0px 10% 20px">
      <div class="step-box">
        <h3>{{ $t('signIn.toResetYourPassword') }}</h3>
      </div>
      <div v-if="active === 0" class="form-box">
        <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            class="demo-ruleForm"
        >
          <el-form-item class="phone-box" prop="phone">
            <el-input
                v-model="ruleForm.phone"
                :placeholder="$t('base.pleaseEnterPhoneNumber')"
            >
              <el-select
                  slot="prepend"
                  v-model="ruleForm.area"
                  :placeholder="$t('signIn.pleaseSelectA')"
                  style="width: 100px"
              >
                <el-option
                    v-for="(item, index) in areaCodeArray"
                    :key="index"
                    :label="item.areaCode"
                    :value="item.areaCode"
                ></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="d-flex">
              <el-input
                  v-model="ruleForm.code"
                  :placeholder="$t('signIn.verificationCode')"
              ></el-input>
              <el-button
                  v-if="sendMsgDisabled"
                  class="code-btn btn-color"
                  @click="sendCode()"
              >{{ $t('signIn.sendVerificationCode') }}
              </el-button
              >
              <el-button
                  v-show="!sendMsgDisabled"
                  :disabled="!sendMsgDisabled"
                  class="code-btn"
              >{{ codeTime }}
              </el-button
              >
            </div>
          </el-form-item>
          <el-form-item class="password-box" prop="pwd">
            <el-input
                v-model="ruleForm.pwd"
                :placeholder="$t('signIn.passwordsAreCaseSensitive')"
                show-password
            ></el-input>
          </el-form-item>
          <el-form-item class="password-confirmation-box" prop="confirmPwd">
            <el-input
                v-model="ruleForm.confirmPwd"
                :placeholder="$t('base.confirmPassword')"
                show-password
            ></el-input>
          </el-form-item>
          <span class="password-tip"
          ><i class="el-icon-info"></i>
            {{ $t('signIn.anAlphanumericSymbol') }}</span
          >
          <el-form-item class="d-flex justify-content-center mar-top-60 w-100">
            <el-button
                style="width: 100%"
                type="primary"
                @click="submitForm('ruleForm')"
            >{{ $t('base.submit') }}
            </el-button
            >
          </el-form-item>
        </el-form>
        <div class="button-box d-flex justify-space-between">
          <el-button type="text" @click="logInNow()"
          ><i class="el-icon-back"></i>{{ $t('base.back') }}
          </el-button
          >
        </div>
      </div>
      <div
          v-else-if="active === 1"
          class="d-flex flex-direction-column align-items-center"
      >
        <div class="d-flex flex-direction-column align-items-center">
          <span class="pad-20-0 fs-16 font-weight-bold">{{
              $t('signIn.passwordResetSuccessful')
            }}</span>
          <img
              alt=""
              class="success-logo"
              height="60"
              src="@/assets/image/home/ic_gong.png"
              width="60"
          />
        </div>
        <div class="d-flex flex-direction-column align-items-center pad-20-0">
          <p class="pad-top-20 font-weight-bold">
            {{ $t('signIn.successfullyResetAccount') }}+{{
              ruleForm.area
            }}{{ ruleForm.phone }}{{ $t('signIn.passwordSuccessful') }}
          </p>
        </div>
        <div
            class="
            d-flex
            flex-direction-column
            justify-content-center
            align-items-center
            pad-top-50
            w-100
            mar-bottom-10
            login-btn
          "
        >
          <p class="pad-top-20">
            {{ $t('signIn.canUseNewPasswordToLogInImmediate') }}
          </p>
          <div class="mat-20 w-100 d-flex justify-content-center">
            <el-button style="width: 100%" type="primary" @click="logInNow()">{{
                $t('signIn.loginImmediate')
              }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {sendCode, resetPassword} from '@/api/base';

export default {
  data() {
    // 区号手机号验证
    let validPhone = (rule, value, callback) => {
      const reg =
          this.ruleForm.area == 86
              ? /^1\d{10}$/
              : this.ruleForm.area == 63
                  ? /^9\d{9}$/
                  : this.ruleForm.area == 60
                      ? /^(([145](-|\s)?\d{7,8})|([236789](-|\s)?\d{7}))$/
                      : this.ruleForm.area == 66 ? /^[0-9]*$/ : this.ruleForm.area == 65 ? /^[0-9]*$/ : '';
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error(this.$t('signIn.pleaseEnterCorrectPhone')));
      }
    };
    const rest =
        /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,16}$/;
    let validatePass = (rule, value, callback) => {
      if (!rest.test(value)) {
        callback(new Error(this.$t('signIn.doNotConformToPasswordFormat')));
      } else {
        callback();
      }
    };
    let validatePassRepeat = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('signIn.pleaseEnterPasswordAgain')));
      } else if (value !== this.ruleForm.pwd) {
        callback(new Error(this.$t('signIn.enterPasswordTwice')));
      } else if (!rest.test(value)) {
        callback(new Error(this.$t('signIn.doNotConformToPasswordFormat')));
      } else {
        callback();
      }
    };
    return {
      active: 0,
      site: this.$route.query.site || '',
      ruleForm: {
        // 区号
        area: '86',
        // 号码
        phone: '',
        // 验证码
        code: '',
        // 密码
        pwd: '',
        // 确认密码
        confirmPwd: '',
      },
      areaCodeArray: [
        {areaCode: '86'},
        {areaCode: '63'},
        {areaCode: '60'},
        {areaCode: '66'},
        {areaCode: '65'},
      ],
      codeTime: 59,
      digits: [
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
      ],
      sendMsgDisabled: true,
      rules: {
        phone: [
          {
            required: true,
            message: this.$t('signIn.pleaseEnterPhoneNumber'),
            trigger: 'blur',
          },
          {required: true, trigger: 'blur', validator: validPhone},
        ],
        code: [
          {
            required: true,
            message: this.$t('signIn.pleaseEnterVerificationCode'),
            trigger: 'blur',
          },
        ],
        pwd: [
          {
            required: true,
            message: this.$t('signIn.pleaseEnterNewPassword'),
            trigger: 'blur',
          },
          {required: true, trigger: 'blur', validator: validatePass},
        ],
        confirmPwd: [
          {
            required: true,
            message: this.$t('signIn.pleaseEnterRepeatNewPassword'),
            trigger: 'blur',
          },
          {required: true, trigger: 'blur', validator: validatePassRepeat},
        ],
      },
    };
  },
  mounted() {
    // console.log(this.$route.query.site);
  },
  methods: {
    sendCode() {
      const data = {
        area: this.ruleForm.area,
        phone: this.ruleForm.phone,
        type: 'change_pwd',
      };
      sendCode(data).then((res) => {
        this.$message.success(res.message);
        this.sendMsgDisabled = false;
        let codeInterval = window.setInterval(() => {
          if (this.codeTime-- <= 1) {
            this.codeTime = 59;
            this.sendMsgDisabled = true;
            window.clearInterval(codeInterval);
          }
        }, 1000);
      });
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          resetPassword(this.ruleForm).then((res) => {
            if (res.status === 200) {
              this.active = 1;
              this.$message.success(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    logInNow() {
      console.log(this.site)
      if (!this.site) {
        this.$router.push({path: '/login'});
      } else {
        const MERCHANT_MAP = {
          '1': 'https://seller.monmallph.com',
          '2': 'https://seller.monmall.my',
          '4': 'https://seller.monmallth.com',
          '8': 'https://seller.monmall.sg',
          '2048': 'https://seller.monmallus.com',
        }
        window.open(MERCHANT_MAP[this.site], '_blank');
      }
    },
  },
};
</script>
<style scoped>
.step-box {
  padding: 60px 0;
  text-align: center;
}

.form-box {
  min-width: 375px;
}

.demo-ruleForm {
  padding: 10px 20%;
}

.six-digit-wrapper {
  display: flex;
  flex-direction: row;
}

.input {
  width: 50px;
  height: 50px;
  font-size: 18px;
  color: #333333;
  text-align: center;
  outline: none;
  border: solid 1px #d2d2d2;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.password-tip {
  font-size: 12px;
  font-weight: 500;
  color: #7c7f86;
}

.login-btn {
  padding: 0 20%;
  box-sizing: border-box;
}

.password-confirmation-box {
  margin-top: 20px;
}

.el-form-item >>> .el-form-item__content {
  width: 100% !important;
}

.code-box {
  display: flex;
}

.code-btn {
  /*min-width: 120px;*/
  margin-left: 10px;
}

.btn-color {
  background: #4285f7;
  color: #fff;
  border: none;
}

.button-box {
  background: #fff;
  padding: 10px;
  border-top: 1px solid #ccc;
  margin-top: 100px;
}
</style>
